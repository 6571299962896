.Box {
    height: 100vh;
    background: url("./index/LineUpTransparent.png") no-repeat;
    background-size: cover;
    background-position: center;
}
.Box .bgImgSty {
    width: '100%';
    height: '100%';
    object-fit: cover;
}
.Box .btnSty {
    width: 100%;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
}
